import './404.less';

import { Link, withI18next } from '@tvai/gatsby-plugin-i18next';

import { Button, Col, Empty, Icon, Row } from 'antd';
import { graphql, navigate } from 'gatsby';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Layout from '../components/Layout';

class NotFoundPage extends Component {
  componentDidMount() {
    if (this.props && this.props.location.pathname) {
      const pathArray = this.props.location.pathname.split('/');
      pathArray.forEach((pathString) => {
        if (pathString === 'trial') {
          navigate('/trial/');
        }
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Layout>
        <div className="not-found-wrapper">
          <div className="container">
            <Row type="flex" align="middle" style={{ height: '50vh' }}>
              <Col span={24}>
                <Empty
                  image={<Icon type="question-circle" theme="filled" style={{ fontSize: '3em', color: '#000' }} />}
                  description={t(`general_info_pagenotfound_description`)}
                >
                  <Link to="/">
                    <Button type="primary" size="large">
                      Back to Homepage
                    </Button>
                  </Link>
                </Empty>
              </Col>
            </Row>
          </div>
        </div>
      </Layout>
    );
  }
}

export default withI18next()(withTranslation()(NotFoundPage));

export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
  }
`;
